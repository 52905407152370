// Make nav sticky on scroll
window.onscroll = function() {
  makeNavSticky();
};

var navbar = document.getElementById('navbar');
var navBarLogo = document.getElementById('navbar-logo');
var iconHome = document.getElementById('icon-home');

function makeNavSticky() {
  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    navbar.classList.add('navbar-white');
    if (navBarLogo !== null) {navBarLogo.src = './assets/img/flyt.svg';}
    if (iconHome !== null) { iconHome.src = './assets/img/icon-home-blue.svg'; }
  } else {
    navbar.classList.remove('navbar-white');
    if (navBarLogo !== null) { navBarLogo.src = './assets/img/flyt-white.svg'; }
    if (iconHome !== null) {
        iconHome.src = './assets/img/icon-home.svg';
    }
  }
}
